<template>
  <div style="margin-left:25px; margin-top:25px;">
    <template>
      <v-sheet color="white" align="center" tile>
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12" sm="12">
            <img
              class="white--text align-end"
              :src="'/'+airlinesBranchId.logo"
              style="width: 100%; height: 550px; object-fit: fill;"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </template>
    <v-sheet color="white" align="center" tile>
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" sm="12">
            <v-title class="display-1"
                     style="font-weight: bold; color:white; background-color:darkblue;padding:5px; border-radius:10px;">{{ airlinesBranchId.name }}</v-title>
          <br />
          <p style="font-weight: bold;margin-top:5px">
              {{ airlinesBranchId.address }}
          </p>
        </v-col>
      </v-row>
    </v-sheet>

    <template>
      <v-row>
        <v-col cols="12" sm="3">
          <v-card color="#006400" elevation="5" style="height:180px">
            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div>
                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                            {{$t('total_no_of_passenger_day')}}
                        </v-list-item-subtitle>
                      <v-icon color="white" x-large>flight</v-icon>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content class="text-right">
                    <div>
                      <v-list-item-subtitle
                        class="white--text"
                        style="font-size: 26px"
                        >{{
                          AviationDailyData.totalPassanger
                        }}</v-list-item-subtitle
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card color="#FFA500" elevation="5" style="height:180px">
            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div>
                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                            {{$t('total_no_of_passenger_month')}}
                      </v-list-item-subtitle>
                      <v-icon color="white" x-large>flight_land</v-icon>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content class="text-right">
                    <div>
                      <v-list-item-subtitle
                        class="white--text"
                        style="font-size: 26px"
                        >{{
                          AviationMonthlyData.totalPassanger
                            }}
                        </v-list-item-subtitle>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card color="#077dfa" elevation="5" style="height:180px">
            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div>
                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                            {{$t('total_no_of_flights_day')}}
                        </v-list-item-subtitle>
                      <v-icon color="white" x-large>flight_takeoff</v-icon>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content class="text-right">
                    <div>
                      <v-list-item-subtitle
                        class="white--text"
                        style="font-size: 26px"
                        >{{
                          AviationDailyData.totalFight
                        }}</v-list-item-subtitle
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card color="#8B0000" elevation="5" style="height:180px">
            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div>
                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                            {{$t('count_of_flight_type_month')}}
                        </v-list-item-subtitle>
                      <v-icon color="white" x-large>flight_class</v-icon>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content class="text-right">
                    <div>
                      <v-list-item-subtitle
                        class="white--text"
                        style="font-size: 26px"
                      >
                        {{
                          AviationMonthlyData.totalFight
                        }}</v-list-item-subtitle
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template>
        <v-row>


            <v-col cols="12" sm="6">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('monthly_performance_of_airlines')}}
                    </h1>
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="monthlyData"
                                    :options="chartOptions"
                                    style="height: 450px" />
                        </div>
                    </template>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card class="pa-5" elevation="5" style="height:531px">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('employees_of_airlines')}}
                    </h1>
                    <v-card>
                        <v-list style="background-color: #1976d2">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title id="table"
                                                       align="center"
                                                       class="white--text">
                                        <strong>{{ $t("employee_name") }}</strong>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="center">
                                    <v-list-item-title id="table" class="mr-18 white--text"><strong>{{ $t("address") }}</strong></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="center">
                                    <v-list-item-title id="table" class="white--text">
                                        <strong>
                                            {{$t("type")}}
                                        </strong>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title id="table" class="white--text">
                                        <strong>
                                            {{$t("contact_no")}}
                                        </strong>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-card style="overflow-y: scroll; height: 350px">
                        <v-card style="height: 350px;">
                            <v-list v-for="(airEmployee, i) in airEmployeeDetails"
                                    :key="i">
                                <v-list-item :style="{ background: airEmployee.backgroundColor }">
                                    <v-list-item-avatar>
                                        <v-title id="table" class="black--text">
                                            {{i + 1}}
                                        </v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table"
                                                           align="center"
                                                           class="black--text">{{ airEmployee.empName }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table"
                                                           class="mr-18 black--text">{{ airEmployee.empAddress }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="black--text">
                                            {{ airEmployee.empType }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="black--text">
                                            {{ airEmployee.empPhone }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </v-list>
                        </v-card>
                    </v-card>
                </v-card>
                    </v-col>
            <v-col cols="12" sm="6">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('destination_flights')}}
                    </h1>
                    <template>
                        <GChart type="PieChart"
                                :options="optionss"
                                :data="data"
                                height="500"
                                align="center" />
                    </template>
                </v-card>
            </v-col>

        </v-row>
    </template>
    <template>
        <v-row>

            <v-col cols="12" sm="12">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('destination_of_airlines')}}
                    </h1>
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="chartData"
                                    :options="chartOptions"
                                    style="height: 350px" />
                        </div>
                    </template>
                </v-card>
            </v-col>
        </v-row>
    </template>
  </div>
</template>

<script>
    import axios from "axios";
    import { mapGetters } from "vuex";
    import { mdiPencil, mdiDelete } from "@mdi/js";
    import { parseJwt } from "@/mixins/parseJwt";
    import publicAxios from "../../../simple-axios";

    export default {
        name: "AviationSettings",
        components: {},
        mixins: [parseJwt],
        computed: {
            ...mapGetters(["getSystemUserData"]),
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true,
            },
        },
        data() {
            return {
                Destinations: [],
                airList: [],
                airlinesBranchId: [],
                airEmployeeDetails: [],
                data: [],
                optionss: {
                    height: 450,
                    is3d: true,
                },

                customerData: {
                    totalRows: 0,
                    editItemIndex: null,
                    deleteFormData: {},
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "personInformationID",
                            type: "desc",
                        },
                    ],
                    page: 1,
                    perPage: 50,
                },

                snackbar: {
                    formModal: false,
                    color: "",
                    snackbar: false,
                    text: "",
                    timeout: 6000,
                    drawer: null,
                    drawerRight: null,
                    right: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dialogDelete: false,
                    dashboardItems: [
                        {
                            name: "Passengers",
                            link: "/AviationCustomerList",
                        },
                        {
                            name: "Generate Report",
                            link: "/report",
                        },
                        {
                            name: "Aviation Profile",
                            link: "/branch",
                        },
                        {
                            name: "Survey",
                            link: "/BranchSurveyProjects",
                        },
                    ],
                    dynamicComponent: {
                        current: "AirDestinationForm",
                    },
                    breadcrumbsItems: [
                        {
                            text: "Dashboard",
                            disabled: true,
                            to: "/dashboard",
                            exact: true,
                        },
                    ],
                },

                branchInformation: [],
                callbackResponse: {
                    timeout: 6000,
                },
                success: false,

                dialogEdit: false,
                dialogDelete: false,
                items: ["Foo", "Bar", "Fizz", "Buzz"],
                province: [],
                AviationDailyData: [],
                AviationMonthlyData: [],
                deleteFormData: {},
                popout: true,
                titles: [
                    {
                        name: "Generate Report",
                        link: "/report",
                    },
                    {
                        name: "Profile",
                        link: "/branch",
                    },
                ],
                svgpencil: mdiPencil,
                svgdelete: mdiDelete,
                dialogCreate: false,
                slides: ["", "", "", ""],
                chartData: [],
                monthlyData: [],
                series: [
                    56090,
                    44961,
                    30200,
                    44336,
                    20350,
                    56210,
                    44219,
                    30212,
                    44320,
                    20120,
                    1200,
                    2130,
                    1239,
                    1236,
                    2810,
                ],
                chartOptions: {
                    chart: {
                        title: "Company Performance",
                        subtitle: "Sales, Expenses, and Profit: 2014-2020",
                    },
                },
                chartsOptions: {
                    chart: {
                        height: 450,
                        is3d: true,
                    },
                    labels: [
                        "Pokhara",
                        "Kathmandu",
                        "Rajbiraj",
                        "Bhairahawa",
                        "Biratnagar",
                        "Bharatpur",
                        "Bhadrapur",
                        "Dhangadhi",
                        "Tumlingtar",
                        "Nepalgunj",
                        "Surkhet",
                        "Janakpur",
                        "Simara",
                        "Varanasi",
                    ],
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 500,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
                totalRecords: 0,
            };
        },
        mounted() {
            this.loadItems();
            this.dashboardData();
            this.AirlinesDashboard();
            this.MonthlyAirlinesDashboard();
            this.formData();
            this.getAirlineBranchId();
            this.getBarAirLinesPerformanceIncomingandOutgoing();
            this.getBarAirlinesMonthlyPerformance();
            this.getPieAirLinestMonthlyPerformance();
            this.getBranchEmployeeDetails();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            async getAirlineBranchId() {

                const { data } = await publicAxios.post("HotelDetails/GetIndividualHotelDetails/" + this.$route.params.airlinesID)

                this.airlinesBranchId = data
                console.log('this.airlinesBranchId', this.airlinesBranchId)
                //const { Individuldata } = await publicAxios.post('HotelDetails/GetIndividualHotelDetails?BranchID')
                //this.IndividulData = Individuldata;
            },
            clearInput() {
                this.serverParams.fullName = null;
                this.serverParams.flightNumber = null;
                this.serverParams.destionation = null;
                /* this.loadItems();*/
            },
            async formData() {
                axios
                    .get("Aviation/DDLAirDestinationByIDAsync")
                    .then((response) => {
                        this.Destinations = response.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                axios
                    .get("Aviation/DDLAirDetailListAsync")
                    .then((response) => {
                        this.airList = response.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            fullName(rowObj) {
                return rowObj.firstName + "   " + rowObj.lastName;
            },
            formResponse(responseObj) {
                this.snackbar.color = responseObj.status ? "success" : "red";
                this.snackbar.text = responseObj.text;
                this.snackbar.snackbar = true;
                this.loadItems();
            },
            dialogueClose() {
                this.customerData.editItemIndex = null;
                this.snackbar.formModal = false;
                this.snackbar.createFormComponent = false;
                this.snackbar.editFormComponent = false;
                this.loadItems();
            },

            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    FullName: this.serverParams.fullName,
                    Groupby: this.serverParams.flightNumber,
                    Destination: this.serverParams.destionation,
                };
                axios
                    .post("Aviation/GetDailyPassengerInformationListAsync", param)
                    .then((response) => {
                        this.rows = response.data.data;
                        console.log("this.rows", this.rows);
                        this.totalRecords = response.data.totalCount;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            async searchInput() {
                this.loadItems();
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field,
                        },
                    ],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true;
                this.snackbar.createFormComponent = true;
            },
            editItem(item) {
                this.snackbar.formModal = true;
                this.snackbar.editFormComponent = true;
                this.customerData.editItemIndex = item.personInformationID;
            },
            deleteItemRoom(item) {
                console.log(item);
                this.snackbar.dialogDelete = true;
                this.customerData.deleteFormData.url =
                    "Aviation/DeletePassengerInformationByIDAsync/" +
                    item.personInformationID;
                this.loadItems();
            },
            onResponseDelete() {
                this.snackbar.dialogDelete = false;
                this.loadItems();
            },
            async dashboardData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );

                this.branchInformation = dashboardData.data;
                console.log("this.branchDetails", this.branchInformation);
            },
            async AirlinesDashboard() {
                const { data } = await publicAxios.post(
                    "Aviation/GetAirlinesDailyDetailsAsync/" + this.$route.params.airlinesID
                );
                this.AviationDailyData = data;
                console.log("this.AviationDailyData", this.AviationDailyData);
            },

            async MonthlyAirlinesDashboard() {
                const { data } = await publicAxios.post(
                    "Aviation/GetAirlinesMonthlyDetailsAsync/" + this.$route.params.airlinesID
                );
                this.AviationMonthlyData = data;
                console.log(
                    "this.GetAirlinesMonthlyDetailsAsync",
                    this.AviationMonthlyData
                );
            },

            async getBarAirLinesPerformanceIncomingandOutgoing() {
                const { data } = await publicAxios.get(
                    "Aviation/GetAirlinePassengerDestinationWiseCountAsync/" + this.$route.params.airlinesID
                );
                let formatedData = this.getChartData(data);
                this.chartData = formatedData;
                console.log(
                    "this.getBarAirLinesPerformanceIncomingandOutgoing",
                    this.chartData
                );
            },
            async getBarAirlinesMonthlyPerformance() {
                const { data } = await publicAxios.get(
                    "Aviation/GetAirlinePassengerMonthCountAsync/" + this.$route.params.airlinesID
                );
                let formatedData = this.getChartData(data);
                this.monthlyData = formatedData;
                console.log("this.getBarAirportMonthlyPerformance", this.monthlyData);
            },

            async getPieAirLinestMonthlyPerformance() {
                const { data } = await publicAxios.post(
                    "Aviation/GetDailyAirlinesFlightsAsync/" + this.$route.params.airlinesID
                );
                let formatedData = this.getChartData(data);
                this.data = formatedData;
                console.log("this.getPieAirportMonthlyPerformance", this.data);
            },
            async getBranchEmployeeDetails() {
                const { data } = await publicAxios.post(
                    "Aviation/GetAirEmployeeDetails/" + this.$route.params.airlinesID
                );
                this.airEmployeeDetails = data;
                console.log("this.airEmployeeDetails", this.airEmployeeDetails);
            },
            getChartData(data) {
                console.log(data);
                let arr = [];
                arr[0] = Object.keys(data[0]);
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1]);
                }
                return arr;
            },
        },
    };
</script>

<style scoped lang="scss">
.options {
  border: 2px solid white;
}
</style>
